import { api } from '~/utils/backend'

export function subscribeToDeviceCommandEvents(session: string, hexacode: string) {
  return api.get('/events/device-commands', {
    params: {
      session: session,
      hexacode: hexacode,
    },
  })
}

export function unsubscribe(subscriptionkey: string, session: string) {
  return api.get('/events/unsubscribe', {
    params: {
      session: session,
      subscriptionkey: subscriptionkey,
    },
  })
}

export function subscribeToDeviceUpdateEvents(session: string, hexacode: string) {
  return api.get('/events/device-update-events', {
    params: {
      session: session,
      hexacode: hexacode,
    },
  })
}

export function subscribeToDeviceStatusEvents(session: string, hexacode: string) {
  return api.get('/events/device-status', {
    params: {
      session: session,
      hexacode: hexacode,
    },
  })
}
